<template>
  <v-card style="margin:1em">
    <list-header :title="listTitle" :groupable="false" :printable="true" :owner="isOwner" :view="view" :people="people" :groups="groupedPeople" @print="print"></list-header>
    <list v-if="view==='list'" :people="people" :is-loading="isLoading" :group="group" :sortable="true" @reload="reload" ref="list"></list>
    <grouped v-else-if="isGrouped" :people="groupedPeople" :isLoading="isLoading" :group="group" :view="view" ref="grouped"></grouped>
    <card v-else-if="view==='card'" :people="people" :is-loading="isLoading" ref="card"></card>
    <photo v-else-if="view==='photo'" :people="people" :is-loading="isLoading" ref="photo"></photo>
    <div v-else>{{ view }}</div>
  </v-card>
</template>
<style>
  @media print {
    div.v-image__image {
      print-color-adjust: exact;
      -webkit-print-color-adjust: exact;
    }
    div.row {
      display: block;
    }
    div.row > div {
      display: inline-block;
      flex: none;
    }
    div.row div {
      page-break-inside: avoid;
    }
  }
</style>
<script>
import { ref, reactive, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    ListHeader: () => import('@/components/greatscots/Header'),
    List: () => import('@/components/greatscots/List'),
    Grouped: () => import('@/components/greatscots/Grouped'),
    Card: () => import('@/components/greatscots/Card'),
    Photo: () => import('@/components/greatscots/Photo')
  },
  setup (props, { root, emit, refs }) {
    const user = computed(() => 'spoof' in root.$store.state.user ? root.$store.state.user.spoof : root.$store.state.user)
    const id = computed(() => root.$route.params['id'])
    const listTitle = ref('')
    const people = reactive([])
    const groupedPeople = reactive([])
    const group = ref('group')
    const isGrouped = computed(() => group.value !== '' && group.value != null && typeof (group.value) !== 'undefined')
    const isOwner = ref(false)

    const isLoading = ref(true)

    onMounted(async () => {
      isLoading.value = true
      if (id.value === 'FacultyStaffDirectory') {
        isOwner.value = false
        listTitle.value = 'Faculty/Staff Alphabetic Directory'
        group.value = null
        const aggregate = [
          { $match: { active: true, person: { $in: ['Faculty', 'Staff'] } } },
          { $sort: { 'name.last': 1, 'name.first': 1 } }
        ]
        const { data } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
        data.forEach((row) => people.push({ ...row }))
      } else if (id.value === 'DepartmentalDirectory') {
        isOwner.value = false
        listTitle.value = 'Faculty/Staff Departmental Directory'
        group.value = 'Department'
        const aggregate = [
          { $match: { active: true, person: { $in: ['Faculty', 'Staff'] } } },
          { $sort: { 'location.department': 1, 'name.last': 1 } }
        ]
        const { data } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
        const groupData = {}
        for (const row of data) {
          const { department } = row.location
          if (department !== '') {
            people.push({ ...row, Department: department })
            if (!(department in groupData)) groupData[department] = []
            groupData[department].push({ ...row, Department: department })
          }
        }
        for (const department in groupData) {
          groupedPeople.push({ group: department, Department: department, people: groupData[department] })
        }
      } else if (id.value.substring(0, 5) === 'Year:') {
        const [, yearCode] = id.value.split(':')
        const yearLevel = (yearCode === 'SR' ? 'Senior' : (yearCode === 'JR' ? 'Junior' : (yearCode === 'SO' ? 'Sophomore' : 'Freshman')))
        isOwner.value = false
        group.value = null
        const dt = new Date()
        listTitle.value = (dt.getFullYear() + (dt.getMonth() < 6 ? 0 : 1)) + ' ' + yearLevel + ' Class List'
        const aggregate = [
          { $match: { active: true, person: 'Student', year: yearCode } },
          { $sort: { 'name.last': 1 } }
        ]
        const { data } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
        data.forEach((row) => people.push({ ...row }))
      } else {
        root.$feathers.service('directory/list').get(id.value, { query: { $populate: 'groups.people' } }).then(({ share, title, groups }) => {
          const temp = share.filter(({ user: shareUser }) => shareUser === user.value.directoryId)
          if (temp.length === 0) {
            // User does not have permission for this list
            root.$store.dispatch('main/snackbar', { color: 'error', text: 'You do not have permission to access the requested list' })
            root.$router.push('/greatscots/lists')
          }
          isOwner.value = share.filter(({ user: shareUser, role }) => shareUser === user.value.directoryId && role === 'owner').length > 0
          listTitle.value = title
          let groupLength = 0
          for (let i = 0; i < groups.length; i++) {
            groupedPeople.push(groups[i])
            const groupName = groups[i].group
            for (let j = 0; j < groups[i].people.length; j++) {
              let temp = Object.assign({}, groups[i].people[j])
              temp.group = groupName
              people.push(temp)
            }
            groupLength++
          }
          if (groupLength <= 1) group.value = null
        })
      }
      isLoading.value = false
    })

    const view = computed(() => { return root.$store.state.greatscots.listView })

    const expandAllGroups = ref(false)
    function print (options) {
      const win = window.open()
      if (!win) {
        alert('It looks like you are blocking popups. To ensure good printing, we have to create a new window. Please enable popups and press the print button again.')
        return
      }
      win.addEventListener('load', () => { console.log('loaded'); win.print() }, true)
      win.document.write('<h1 style="text-align:center">' + listTitle.value + '</h1>')
      if (view.value === 'list') {
        refs.list.print(win, options)
      } else if (group.value !== '' && group.value != null && typeof (group.value) !== 'undefined') {
        refs.grouped.print(win)
      } else if (view.value === 'card') {
        refs.card.print(win)
      } else if (view.value === 'photo') {
        refs.photo.print(win)
      }
    }

    const reload = (args) => {
      emit('reload', args)
    }

    return {
      user,
      id,
      listTitle,
      people,
      groupedPeople,
      group,
      isGrouped,
      isOwner,
      isLoading,
      expandAllGroups,
      print,
      view,
      reload
    }
  }
}
</script>
